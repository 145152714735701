<template>
	<div class="nav">
		<router-link to="/">Back</router-link>
	</div>
	<div ref="aboutArea" class="area scrollable"></div>
</template>

<style scoped>
div, a, a:visited, a:hover, a:active {
	color: white;
}

div.nav {
	padding: 30px;
	text-align: left;
}
div.nav a {
	font-weight: bold;
}

div.area {
	position: absolute;
	top: 50px;
	bottom: 10px;
	left: 10px;
	right: 10px;
	padding-left: 20px;
	padding-right: 20px;
	text-align: left;
}
</style>

<script>
export default {
	name: 'About',
	async mounted () {
		var target = !!this.$route.query.markup ? 'markup' : 'main';
		try {
			let result = await sendRequest('/about', target);

			while (true) {
				if (!!window.MarkUp) break;
				await wait(100);
			}
			this.$refs.aboutArea.classList.add('markup');
			let content = MarkUp.parse(result);
			this.$refs.aboutArea.innerHTML = content;
			// .replace(/<\/?(article|section)>/gi, '');
		}
		catch (err) {
			console.error(err);
		}
	}
}
</script>